const redirectFromQuickstart = (apiOfInterest) => {
  let redirectPath = '/';

  if (window.location.search.match(/target=([^&]*)/)) {
    redirectPath = window.location.search.match(/target=([^&]*)/)[1];
    redirectPath = redirectPath + '?source=signup';
  } else {
    redirectPath = `/api/${apiOfInterest}/tester`;
  }

  return redirectPath;
};

const removeParameterFromUrl = (url, parameter) => {
  return url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
};

const displayTestResponse = ({ response, productId, setTestInformation }) => {
  const statusCode = response.status;

  let data = response.data;
  if (productId === 'scrape') {
    data = data.replace('<', '&lt;');
    data = data.replace('>', '&gt;');
  } else {
    data = JSON.stringify(response.data, undefined, 4);
  }

  setTestInformation((prev) => ({ ...prev, result: data }));

  const statusMap = {
    200: { text: 'Success', color: 'green' },
    400: { text: 'Bad request', color: 'red' },
    401: { text: 'Unauthorized', color: 'red' },
    402: { text: 'Payment required', color: 'yellow' },
    422: { text: 'Quota reached', color: 'yellow' },
    429: { text: 'Too many requests', color: 'yellow' },
  };

  const status = statusMap[statusCode];
  if (status) {
    setTestInformation((prev) => ({
      ...prev,
      statusText: status.text,
      statusColor: status.color,
    }));
  }
};

const productPropertyByProductId = {
  6: 'email',
  2: 'ip_address',
  7: 'phone',
};

const truncateUrl = (url) => {
  const parts = url.split('/');
  let baseUrl = '';
  if (parts.includes('v2')) {
    baseUrl = parts.slice(0, parts.indexOf('v2') + 1).join('/');
  } else {
    baseUrl = parts.slice(0, parts.indexOf('v1') + 1).join('/');
  }
  if (url.includes('&')) {
    const api = url.split('&').pop();
    return `${baseUrl}?${api}`;
  }
  return baseUrl;
};

export {
  displayTestResponse,
  redirectFromQuickstart,
  removeParameterFromUrl,
  productPropertyByProductId,
  truncateUrl,
};
