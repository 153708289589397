import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LockClosedIcon } from '@heroicons/react/outline';

import CacheContext from '../../../../contexts/CacheContext';
import {
  trackEventPageLoad,
  trackEvent,
} from '../../../Utils/SegmentAnalytics';
import FeedbackModal from './FeedbackModal';
import FeedbackPopover from './FeedbackPopover';
import TESTER_STRINGS from './Tester.strings';
import { handleIpAddressFetching } from '../../../Utils/Helpers';
import {
  formatIndent,
  removeParameterFromUrl,
  getCodeSnippet,
  displayTestResponse,
} from './Tester.helpers';

const Tester = ({
  productName,
  productId,
  productTestUrl,
  apiKey,
  match,
  api,
}) => {
  // Context
  const { getUserInformation } = useContext(CacheContext);
  const [testUrl, setTestUrl] = useState('');
  const [testUrlEditable, setTestUrlEditable] = useState('');
  const [testInformation, setTestInformation] = useState({
    statusColor: 'indigo',
    statusText: '',
    result: '',
  });

  const [selectedLanguage, setSelectedLanguage] = useState('liveTest');
  const [showPopover, setShowPopover] = useState(false);
  const [isShowingFeedbackModal, setIsShowingFeedbackModal] = useState(false);

  const popoverRefElement = useRef(null);

  const languages = TESTER_STRINGS.LANGUAGES;
  const [copiedSnippetLanguage, setCopiedSnippetLanguage] = useState('');
  const [queryIpAddress, setQueryIpAddress] = useState('');

  const generateUrl = useCallback(
    function (productId, productTestUrl, apiKey) {
      var url = `${productTestUrl}/?api_key=${apiKey}`;
      var params = '';
      handleIpAddressFetching(setQueryIpAddress);

      switch (productId) {
        case 6:
          // email-validation
          let email = getUserInformation().email;
          params = '&email=' + email;
          return url + params;
        case 7:
          // phone-validation
          params = '&phone=14152007986';
          return url + params;
        case 5:
          // VAT
          params = '&vat_number=SE556656688001';
          return url + params;
        case 3:
          // Holidays
          params = '&country=US&year=2020&month=12&day=25';
          return url + params;
        case 2:
          // ip-geolocation
          return url + queryIpAddress;
        case 13:
          // timezone
          params = '&location=Oxford, United Kingdom';
          return url + params;
        case 4:
          // avatars
          params = '&name=Claire Florentz';
          return url + params;
        case 8:
          // screenshot
          params = '&url=https://news.ycombinator.com';
          return url + params;
        case 9:
          // scrape
          params = '&url=https://news.ycombinator.com';
          return url + params;
        case 10:
          // images
          params = '&phone=14152007986';
          return url + params;
        case 14:
          // Company Enrichment
          params = '&domain=airbnb.com';
          return url + params;
        case 15:
          // Exchange rates
          params = '&base=USD&target=EUR';
          return url + params;
        case 16:
          // IBAN validation
          params = '&iban=BE71096123456769';
          return url + params;
        default:
          return '';
      }
    },
    [getUserInformation, queryIpAddress],
  );

  useEffect(() => {
    let generatedUrl = generateUrl(productId, productTestUrl, apiKey);
    setTestUrl(generatedUrl);
    setTestUrlEditable(generatedUrl);

    trackEventPageLoad('product tester');
  }, [productId, productTestUrl, apiKey, generateUrl]);

  const performTestRequest = async (event) => {
    setTestInformation({
      statusColor: 'indigo',
      statusText: 'Request sent',
      result: 'Awaiting response...',
    });

    event.preventDefault();

    // Display response panel if API is not image, avatar or screenshot

    if (![4, 8].includes(productId)) {
      // Perform request
      await axios
        .get(testUrlEditable)
        .then((response) => {
          displayTestResponse({ response, setTestInformation });
          const timeoutId = setTimeout(setShowPopover(true), 6000);
          if (showPopover) clearTimeout(timeoutId);

          // Track
          let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');
          trackEvent('Test API call completed', {
            request_url: requestUrl,
            response_status: response.status,
          });
        })
        .catch((error) => {
          displayTestResponse({ response: error.response, setTestInformation });

          // Track
          let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');
          trackEvent('Test API call completed', {
            request_url: requestUrl,
            response_status: error.response.status,
          });
        });
    } else {
      const timeoutId = setTimeout(setShowPopover(true), 6000);
      if (showPopover) clearTimeout(timeoutId);

      const newWindow = window.open(
        testUrlEditable,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) {
        newWindow.opener = null;
      }
      let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');
      trackEvent('Test API call completed', {
        request_url: requestUrl,
      });
    }
  };

  return (
    <>
      <h2 className="text-xl font-medium">Try it out</h2>

      <div className="mt-4 text-sm">
        This is your private API key, specific to this API.
        <br />
      </div>

      <div className="mt-4 text-sm">
        Primary Key:
        <span className="flex-row py-2 px-2 w-auto rounded-md font-bold bg-blue-brand-100 text-blue-brand-500 text-xs">
          <LockClosedIcon className="h-4 w-4 mr-1 inline" aria-hidden="true" />
          {apiKey}
        </span>
      </div>
      <div className="mt-4 text-sm">
        <NavLink
          to={`/api/${match.params.api}/settings`}
          className="hover:bg-blue-brand-100 text-blue-brand-500"
        >
          Create, rotate and manage your keys
        </NavLink>
      </div>
      <div>
        <div className="mt-4 text-sm" ref={popoverRefElement}>
          <Tab.Container defaultActiveKey="liveTest" unmountOnExit={true}>
            <Nav
              className="-mb-px flex border-b border-gray-200 "
              style={{ height: 40 }}
            >
              {productId !== 10 ? (
                <>
                  <Nav.Item
                    className={
                      'text-center mt-2 border-b-2 font-medium text-sm hover:border-blue-brand-500 hover:text-blue-brand-500 ' +
                      (selectedLanguage === 'liveTest'
                        ? 'border-blue-brand-500 text-blue-brand-500'
                        : '')
                    }
                  >
                    <Nav.Link
                      className={'py-2 px-2'}
                      eventKey="liveTest"
                      onClick={() => setSelectedLanguage('liveTest')}
                    >
                      Live test
                    </Nav.Link>
                  </Nav.Item>
                  {languages.map((language) => {
                    return (
                      <Nav.Item
                        key={'nav-item-' + language}
                        className={
                          'text-center mt-2 border-b-2 font-medium text-sm hover:border-blue-brand-500 hover:text-blue-brand-500 ' +
                          (selectedLanguage === language
                            ? 'border-blue-brand-500 text-blue-brand-500'
                            : '')
                        }
                      >
                        <Nav.Link
                          className={'py-2 px-2'}
                          eventKey={language}
                          onClick={() => {
                            setSelectedLanguage(language);
                            setCopiedSnippetLanguage('');
                          }}
                        >
                          {language}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </>
              ) : (
                <Nav.Item
                  key={'nav-item-cURL'}
                  className={
                    'py-2 px-2 text-center border-b-2 font-medium text-sm hover:border-blue-brand-500 hover:text-blue-brand-500 ' +
                    (selectedLanguage === 'cURL'
                      ? 'border-blue-brand-500 text-blue-brand-500'
                      : '')
                  }
                >
                  <Nav.Link
                    eventKey="cURL"
                    onClick={() => setSelectedLanguage('cURL')}
                  >
                    cURL
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item
                className={
                  'mt-2 text-center border-b-2 font-medium text-sm hover:border-blue-brand-500 hover:text-blue-brand-500 ' +
                  (selectedLanguage === 'postman'
                    ? 'border-blue-brand-500 text-blue-brand-500'
                    : '')
                }
              >
                <Nav.Link
                  className={'py-2 px-2'}
                  eventKey="postman"
                  onClick={() => setSelectedLanguage('postman')}
                >
                  Postman
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="mt-2">
              {productId !== 10 ? (
                <>
                  <Tab.Pane eventKey="liveTest">
                    <form>
                      <textarea
                        value={testUrlEditable}
                        className="text-sm h-30 p-10 w-full border border-gray-200 focus:outline-none focus:border-blue-brand-500 border-blue-brand-200"
                        onChange={(e) => setTestUrlEditable(e.target.value)}
                      />
                      {testInformation.result.length > 0 ? (
                        <div
                          id="sample-result"
                          style={{ marginTop: '5px', position: 'relative' }}
                        >
                          <div>
                            <span
                              className={`absolute top-5 right-5 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-${testInformation.statusColor}-100 text-${testInformation.statusColor}-800`}
                            >
                              <svg
                                className={`-ml-0.5 mr-1.5 h-2 w-2 text-${testInformation.statusColor}-400`}
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx={4} cy={4} r={3} />
                              </svg>
                              {testInformation.statusText}
                            </span>
                          </div>
                          <SyntaxHighlighter
                            language="javascript"
                            style={docco}
                            wrapLongLines={true}
                            customStyle={{
                              padding: '30px',
                              border: '1px solid #e2e5ec',
                            }}
                          >
                            {formatIndent(testInformation.result)}
                          </SyntaxHighlighter>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="mt-4">
                        <div className="flex justify-end">
                          <a
                            href={`https://docs.abstractapi.com/${api}`}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            target="_blank"
                          >
                            Documentation
                          </a>
                          <button
                            onClick={performTestRequest}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Make test request
                          </button>
                        </div>
                      </div>
                    </form>
                  </Tab.Pane>
                  {languages.map((language) => {
                    return (
                      <Tab.Pane
                        eventKey={language}
                        key={'tab-pane-' + language}
                      >
                        <SyntaxHighlighter
                          className="text-xs"
                          language=""
                          style={docco}
                          wrapLongLines={true}
                          customStyle={{ padding: '30px' }}
                        >
                          {getCodeSnippet(language, productId, testUrl, apiKey)}
                        </SyntaxHighlighter>

                        <div className="mt-4">
                          <div className="flex justify-end">
                            <a
                              href={`https://docs.abstractapi.com/${api}`}
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              target="_blank"
                            >
                              Documentation
                            </a>
                            <CopyToClipboard
                              text={getCodeSnippet(
                                selectedLanguage,
                                productId,
                                testUrl,
                                apiKey,
                              )}
                              onCopy={() =>
                                setCopiedSnippetLanguage(selectedLanguage)
                              }
                            >
                              <button className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                {!copiedSnippetLanguage
                                  ? 'Copy code'
                                  : 'Copied'}
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Tab.Pane>
                    );
                  })}
                </>
              ) : (
                <Tab.Pane eventKey={'cURL'} key={'tab-pane-cURL'}>
                  <SyntaxHighlighter
                    language=""
                    style={docco}
                    wrapLongLines={true}
                    customStyle={{ padding: '30px' }}
                  >
                    {getCodeSnippet('cURL', productId, testUrl, apiKey)}
                  </SyntaxHighlighter>

                  <div className="mt-4">
                    <div className="flex justify-end">
                      <a
                        href={`https://docs.abstractapi.com/${api}`}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        target="_blank"
                      >
                        Documentation
                      </a>
                      <CopyToClipboard
                        text={getCodeSnippet(
                          'cURL',
                          productId,
                          testUrl,
                          apiKey,
                        )}
                        onCopy={() => setCopiedSnippetLanguage('cURL')}
                      >
                        <button className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                          {copiedSnippetLanguage !== 'cURL'
                            ? 'Copy code'
                            : 'Copied'}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </Tab.Pane>
              )}

              <Tab.Pane eventKey="postman">
                <div className={'mt-4 mb-4'}>
                  Get a template collection of all Abstract's endpoint directly
                  into Postman. Click the button below to import Abstract
                  collection
                </div>
                <a
                  className="mt-3 inline-flex rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-brand-500 text-white hover:bg-blue-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:w-auto text-sm"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{ marginTop: '10px' }}
                  href={
                    'https://app.getpostman.com/run-collection/7474a4d2ac06f66a0191'
                  }
                >
                  <div className={'translate-y-0.5'}>
                    <i className="fa fa-play mr-2 " />
                  </div>
                  <span>Open Postman collection</span>
                </a>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <FeedbackPopover
            referenceElement={popoverRefElement}
            productName={productName}
            setIsShowingFeedbackModal={setIsShowingFeedbackModal}
            onClose={() => setShowPopover(false)}
            isOpen={showPopover}
          />
          <FeedbackModal
            isShowingFeedbackModal={isShowingFeedbackModal}
            productName={productName}
            onClose={() => setIsShowingFeedbackModal(false)}
            testUrl={testUrlEditable}
          />
        </div>
      </div>
    </>
  );
};

export default Tester;
