export const builtURLRedirectBanner = (promoCode) => {
  let urlQuery = `plan_id=${promoCode.plan.id}&pricing_cta=upgrade_banner_discount&coupon_discount=${promoCode.promotion_code.code}`;
  urlQuery += `&banner=true&product_id=${promoCode.plan.api.id}&coupon=${promoCode.promotion_code.stripe_id}`;
  return `${window.location.href}/?${urlQuery}`;
};

export function getNextMonthName() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  return currentDate.toLocaleString('default', { month: 'long' });
}

export async function shouldShowBanner(userInfo) {
  try {
    if (!userInfo?.created) {
      return false;
    }

    const currentDate = new Date();
    const userCreatedDate = new Date(userInfo.created);
    const daysSinceCreation =
      Math.floor((currentDate - userCreatedDate) / (1000 * 60 * 60 * 24)) || 1;

    return daysSinceCreation > 3;
  } catch (error) {
    console.error('Error in shouldShowBanner:', error);
    return false;
  }
}
