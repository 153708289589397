import React, { createContext, useState } from 'react';

const SessionContext = createContext();

const SessionProvider = (props) => {
  const [isUserImpersonated, setIsUserImpersonated] = useState(
    localStorage.getItem('user_impersonated')
      ? localStorage.getItem('user_impersonated')
      : false,
  );

  const isUserAuthenticated = () => {
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('refresh_token')
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Tokens
  const loginUser = (tokens) => {
    localStorage.setItem('access_token', tokens.access);
    localStorage.setItem('refresh_token', tokens.refresh);
  };

  const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_impersonated');
    localStorage.removeItem('user_id');
    localStorage.removeItem('has_usable_password');
    window.location.reload();
  };

  const getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };

  // Impersonation
  const startImpersonation = (impersonatedUserstokens) => {
    // Storing existing token for later
    localStorage.setItem(
      'original_access_token',
      localStorage.getItem('access_token'),
    );
    localStorage.setItem(
      'original_refresh_token',
      localStorage.getItem('refresh_token'),
    );

    // Switching tokens with impersonated user's ones
    loginUser(impersonatedUserstokens);

    // Changing status
    localStorage.setItem('user_impersonated', true);
    setIsUserImpersonated(true);
  };

  const stopImpersonation = () => {
    // Reverting back to original access tokens
    localStorage.setItem(
      'access_token',
      localStorage.getItem('original_access_token'),
    );
    localStorage.setItem(
      'refresh_token',
      localStorage.getItem('original_refresh_token'),
    );
    localStorage.removeItem('original_access_token');
    localStorage.removeItem('original_refresh_token');

    // Changing status
    localStorage.removeItem('user_impersonated');
    setIsUserImpersonated(false);
  };

  const updateAccessToken = (accessToken) => {
    localStorage.setItem('access_token', accessToken);
  };

  return (
    <SessionContext.Provider
      value={{
        loginUser: loginUser,
        logoutUser: logoutUser,
        startImpersonation: startImpersonation,
        stopImpersonation: stopImpersonation,
        isUserImpersonated: isUserImpersonated,
        getAccessToken: getAccessToken,
        getRefreshToken: getRefreshToken,
        updateAccessToken: updateAccessToken,
        isUserAuthenticated: isUserAuthenticated,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext as default, SessionProvider };
