import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SubHeader from '../Layout/SubHeader/SubHeader';
import { trackEventPageLoad } from '../Utils/SegmentAnalytics';
import productsJson from '../../assets/json/products.json';
import * as HeroIcons from '@heroicons/react/outline';
import { useShowBanner } from '../Banner/useShowBanner';
import Banner from '../Banner/Banner';

function Dashboard(props) {
  const [productsCategories, setProductsCategories] = useState({});
  const [products, setProducts] = useState({});
  const { showBanner, promoCode } = useShowBanner('dashboard');

  useEffect(() => {
    getProducts();
    trackEventPageLoad('dashboard');
  }, []);

  const getProducts = async () => {
    setProducts(productsJson.products);
    setProductsCategories(productsJson.categories);
  };

  const renderIcon = (product) => {
    const components = {
      BellIcon: HeroIcons.BellIcon,
      MailIcon: HeroIcons.MailIcon,
      PhoneIcon: HeroIcons.PhoneIcon,
      TagIcon: HeroIcons.TagIcon,
      ReceiptTaxIcon: HeroIcons.ReceiptTaxIcon,
      CalendarIcon: HeroIcons.CalendarIcon,
      CashIcon: HeroIcons.CashIcon,
      OfficeBuildingIcon: HeroIcons.OfficeBuildingIcon,
      ClockIcon: HeroIcons.ClockIcon,
      UserCircleIcon: HeroIcons.UserCircleIcon,
      CameraIcon: HeroIcons.CameraIcon,
      GlobeAltIcon: HeroIcons.GlobeAltIcon,
      PhotographIcon: HeroIcons.PhotographIcon,
      LocationMarkerIcon: HeroIcons.LocationMarkerIcon,
      LibraryIcon: HeroIcons.LibraryIcon,
    };

    var iconName = products[product]['icon'];

    if (iconName in components) {
      let IconComponentName = components[iconName];
      return (
        <IconComponentName
          className={
            'text-gray-brand-300 group-hover:text-gray-brand-500 flex-shrink-0 h-5 w-5'
          }
        />
      );
    } else {
      return <> </>;
    }
  };

  return (
    <div>
      {showBanner && (
        <Banner promoCode={promoCode} currentComponent="dashboard" />
      )}
      <SubHeader title="Dashboard" />
      <div className="mt-2 bg-white overflow-hidden shadow-small rounded-md">
        <div className="p-12">
          <h3 className="text-xl font-medium">Welcome to Abstract.</h3>
          <div className="mt-4 text-sm">
            <span>
              Abstract provides powerful APIs to help you enrich any user
              experience or automate any workflow.{' '}
            </span>
            <br />
            <span>
              Your account comes with{' '}
              <span className="text-gray-brand-500">free credits</span> across
              all Abstract APIs.
            </span>
          </div>
        </div>
      </div>

      <div className="mt-3 flex flex-wrap overflow-hidden xl:-mx-3">
        <div className="w-full overflow-hidden lg:w-1/3 my-3 xl:px-3 xl:w-1/3">
          <div className="h-full bg-white overflow-hidden shadow-small rounded-md">
            <div className="p-12">
              <h3 className="text-xl font-medium">Lookup</h3>
              <div className="mt-4 text-sm">
                <span>
                  Enrich your app or service with accurate and comprehensive
                  data from Abstract
                </span>
              </div>
              <div className="mt-5">
                {Object.keys(productsCategories).length > 0 ? (
                  productsCategories['lookup'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      className="text-gray-600 -mx-2 px-2 hover:bg-gray-brand-100 text-gray-brand-500 group flex items-center py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="ml-3 whitespace-nowrap">
                        {products[product]['name']}
                      </span>
                    </NavLink>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full overflow-hidden lg:w-1/3 my-3 xl:px-3 xl:w-1/3">
          <div className="h-full bg-white overflow-hidden shadow-small rounded-md">
            <div className="p-12">
              <h3 className="text-xl font-medium">Create</h3>
              <div className="mt-4 text-sm">
                <span>
                  Create or retrieve assets from a variety of data collected or
                  created by Abtract
                </span>
              </div>
              <div className="mt-5">
                {Object.keys(productsCategories).length > 0 ? (
                  productsCategories['create'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      className="text-gray-600 -mx-2 px-2 hover:bg-gray-brand-100 text-gray-brand-500 group flex items-center py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="ml-3 whitespace-nowrap">
                        {products[product]['name']}
                      </span>
                    </NavLink>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full overflow-hidden lg:w-1/3 my-3 xl:px-3 xl:w-1/3">
          <div className="h-full bg-white overflow-hidden shadow-small rounded-md">
            <div className="p-12">
              <h3 className="text-xl font-medium">Verify</h3>
              <div className="mt-4 text-sm">
                <span>
                  Protect your app and ensure compliance with verification APIs{' '}
                </span>
              </div>
              <div className="mt-5">
                {Object.keys(productsCategories).length > 0 ? (
                  productsCategories['verify'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      className="text-gray-600 -mx-2 px-2 hover:bg-gray-brand-100 text-gray-brand-500 group flex items-center py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="ml-3 whitespace-nowrap">
                        {products[product]['name']}
                      </span>
                    </NavLink>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
