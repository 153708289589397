import { createContext, useContext, useEffect, useState } from 'react';
import CacheContext from './CacheContext';
import SessionContext from './SessionContext';
import { getMainInterestFromParam } from '../components/Onboarding/helpers';
import { getLocalDateAndTime } from '../components/Utils/Helpers';
import { trackEvent } from '../components/Utils/SegmentAnalytics';

import axios from 'axios';

const APIContext = createContext();

const APIProvider = (props) => {
  const {
    loginUser,
    logoutUser,
    getAccessToken,
    getRefreshToken,
    updateAccessToken,
    startImpersonation,
    stopImpersonation,
  } = useContext(SessionContext);

  const {
    storeUserInformation,
    storeUserApisData,
    getUserInformation,
    resetUserData,
  } = useContext(CacheContext);

  const [userInfo, setUserInfo] = useState(null);
  const [userApisData, setUserApisData] = useState(null);

  // INTERCEPTOR TO REFRESH AUTH TOKENS
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (
        err.response &&
        err.response.status === 401 &&
        err.response.data &&
        err.response.data.code &&
        err.response.data.code === 'token_not_valid' &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        return new Promise((resolve, reject) => {
          // Fixing error and retrying request if possible
          const originalReq = err.config;

          // Token expired. Refreshing and retrying request

          originalReq._retry = true;

          let url = process.env.REACT_APP_API + '/token/refresh/';
          let body = JSON.stringify({
            refresh: getRefreshToken(),
          });

          let res = fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getAccessToken()}`,
            },
            body: body,
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data && data.access) {
                // Updating refresh token and retrying original request
                let accessToken = data.access;
                updateAccessToken(accessToken);
                originalReq.headers['Authorization'] = `Bearer ${accessToken}`;
                return axios(originalReq);
              } else {
                // Logout: Refresh token likely expired
                resetUserData();
                logoutUser();
                return Promise.error();
              }
            });
          resolve(res);
          return Promise.reject(err);
        });
      } else {
        throw err;
      }
    },
  );

  const APISignupByGoogle = async (
    params,
    source,
    context,
    setDisplayErrorMessage,
    setErrorMessage,
    history,
  ) => {
    let url = process.env.REACT_APP_API + '/users/google/signup/';
    const names = { signin: 'login', signup: 'signup' };

    return await axios
      .post(url, params)
      .then((response) => {
        loginUser(response.data.tokens);
        setDisplayErrorMessage('none');
        setErrorMessage('');
        APIrefreshUserData();
        localStorage.setItem(
          'has_usable_password',
          response.data.has_usable_password,
        );
        let user_info = {
          user_id: response.data.user_id,
          google_source: source,
        };
        trackEvent('Google login successful', user_info);
        if (response.data.created) {
          trackEvent('Google signup successful', user_info);
          let redirectPath = '/onboarding';
          let target = new URLSearchParams(window.location.search).get(
            'target',
          );
          if (target) {
            redirectPath += `?target=/api/${target}/pricing/select&pricing_cta=onboarding`;
            getMainInterestFromParam(target);
          }
          let selected_plan = new URLSearchParams(window.location.search).get(
            'selected_plan',
          );
          if (selected_plan) {
            redirectPath += `&selected_plan=${selected_plan}`;
          }
          history.push(redirectPath);
          return;
        }
        history.push('/dashboard');
        return;
      })
      .catch((error) => {
        var message = '';
        try {
          message = error.response.data.error.message;
        } catch (error) {
          message = 'An error ocuured try again please.';
        }
        trackEvent(`Google ${names[context]} rejected`, {
          error: message,
          google_source: source,
        });
        setErrorMessage(message);
        setDisplayErrorMessage('block');
      });
  };

  const APIgetLocalProduct = async (api) => {
    let url = '/assets/json/products.json';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    return await axios(options).then(function (response) {
      if (
        response.data &&
        response.data.products &&
        response.data.products.hasOwnProperty(api)
      ) {
        return response.data.products[api];
      } else {
        return {};
      }
    });
  };

  // REMOTE ENDPOINTS
  // Auth
  const APIsignup = async (
    email,
    password,
    captcha,
    signupSourceData,
    token,
  ) => {
    let url = process.env.REACT_APP_API + '/users/';

    let body = JSON.stringify({
      first_name: '',
      last_name: '',
      email: email ? email.toLowerCase() : '',
      password: password,
      signup_source_data: signupSourceData,
      token: token,
      captcha: captcha,
    });

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true',
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIlogin = async (email, password) => {
    let url = process.env.REACT_APP_API + '/token/';

    let body = JSON.stringify({
      email: email ? email.toLowerCase() : '',
      password: password,
    });

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true',
      data: body,
    };

    return await axios(options).then((response) => {
      if (response.status === 200) {
        // Store auth
        loginUser(response.data);
      }
      return response;
    });
  };

  const APIlogout = async () => {
    let url = process.env.REACT_APP_API + '/users/blacklist-refresh-token/';

    let body = {
      refresh: getRefreshToken(),
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: body,
    };

    return await axios(options)
      .then((response) => {})
      .catch((error) => {})
      .finally(() => {
        resetUserData();
        logoutUser();
      });
  };

  //
  const APIstartImpersonation = async (email) => {
    let url = process.env.REACT_APP_API + '/users/impersonation/';

    let body = JSON.stringify({
      email: email ? email.toLowerCase() : '',
    });

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true',
      data: body,
    };

    return await axios(options).then((response) => {
      if (response.status === 200) {
        // Store auth
        startImpersonation(response.data);
        APIrefreshUserData();
      }
      return response;
    });
  };

  const APIgetSuggestedPlan = async (planId, offeredApiName) => {
    let url = `${process.env.REACT_APP_API}/plans/${planId}/get-suggested-plan/?offered_api_name=${offeredApiName}`;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    return await axios(options)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((error) => {
        console.error('Error fetching suggested plan:', error);
        throw error;
      });
  };

  //
  const APIstopImpersonation = async () => {
    let url = process.env.REACT_APP_API + '/users/impersonation-stop/';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true',
    };

    return await axios(options).then((response) => {
      if (response.status === 200) {
        // Store auth
        stopImpersonation();
        APIrefreshUserData();
      }
      return response;
    });
  };

  // User data
  const APIrefreshUserData = async () => {
    // First try to get access token
    let accessToken = getAccessToken();

    // If no access token, try to refresh using refresh token
    if (!accessToken) {
      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          console.log('No refresh token available');
          return {};
        }

        const url = process.env.REACT_APP_API + '/token/refresh/';
        const refreshResponse = await axios.post(url, {
          refresh: refreshToken,
        });

        if (refreshResponse.data?.access) {
          // Update access token
          updateAccessToken(refreshResponse.data.access);
          accessToken = refreshResponse.data.access;
        } else {
          console.log('Token refresh failed - no access token returned');
          resetUserData();
          logoutUser();
          return {};
        }
      } catch (refreshError) {
        console.log('Token refresh failed:', refreshError);
        resetUserData();
        logoutUser();
        return {};
      }
    }

    // Now proceed with user data fetch using valid access token
    let url = process.env.REACT_APP_API + '/users/?historical_days=30';
    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios(options);
      var userData = response.data;
      let apisData = userData['api_keys'];
      delete userData['api_keys'];

      // Storing in cache
      storeUserInformation(userData);
      storeUserApisData(apisData);
      setUserInfo(userData);
      setUserApisData(apisData);
      return response;
    } catch (error) {
      // Check if error is due to invalid token
      if (error.response?.status === 401) {
        // Try to refresh the token
        try {
          const refreshToken = getRefreshToken();
          if (!refreshToken) {
            console.log('No refresh token available');
            logoutUser();
            return {};
          }

          const url = process.env.REACT_APP_API + '/token/refresh/';
          const refreshResponse = await axios.post(url, {
            refresh: refreshToken,
          });

          if (refreshResponse.data?.access) {
            // Update access token
            updateAccessToken(refreshResponse.data.access);
            // Retry the original request
            return APIrefreshUserData();
          }
        } catch (refreshError) {
          console.log('Token refresh failed:', refreshError);
          // If refresh fails, logout user
          resetUserData();
          logoutUser();
          return {};
        }
      }

      console.log('Error fetching user data:', error);
      return {};
    }
  };

  const APIchangeEmail = async (email, password) => {
    let userId = getUserInformation().id;
    let url = process.env.REACT_APP_API + '/users/' + userId + '/change-email/';
    let body = {
      email: email ? email.toLowerCase() : '',
      password: password,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: body,
    };

    return await axios(options).then((response) => {
      console.log(response);
    });
  };

  const APIconfirmChangeEmail = async (email, token) => {
    let url = process.env.REACT_APP_API + '/users/confirm-email/';

    let body = {
      email: email ? email.toLowerCase() : '',
      token: token,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true'
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIchangePassword = async (email) => {
    let url = process.env.REACT_APP_API + '/users/forgot-password/';
    let body = {
      email: email ? email.toLowerCase() : '',
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true'
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIconfirmPasswordChange = async (email, password, token) => {
    let url = process.env.REACT_APP_API + '/users/confirm-password/';

    let body = {
      email: email ? email.toLowerCase() : '',
      password: password,
      token: token,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true'
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  // Organization
  const APIGetOrganization = async () => {
    let url = process.env.REACT_APP_API + '/users/organization/';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  const APIgetOrganizationMembers = async () => {
    let url = process.env.REACT_APP_API + '/users/organization/members/';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  const APIinviteOrganizationMember = async (emails) => {
    let url = process.env.REACT_APP_API + '/users/organization/members/';

    let body = JSON.stringify({
      emails,
    });

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: body,
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  const APIDeleteOrganizationMember = async (email) => {
    let url = process.env.REACT_APP_API + '/users/organization/members/';

    let body = JSON.stringify({
      email: email,
    });

    let options = {
      url: url,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: body,
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  // APIs
  const APIgetProducts = async () => {
    let url = process.env.REACT_APP_API + '/products/';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  const APIgetSelectedPlan = async () => {
    let options = {
      url: process.env.REACT_APP_API + '/users/user-selected-plan/',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response.data;
    });
  };

  const APIupdatePlan = async (
    paymentMethodId,
    currentFlatPriceId,
    currentMeteredPriceId,
    newFlatPriceId,
    newMeteredPriceId,
    coupon,
  ) => {
    let userId = getUserInformation().id;
    let url = process.env.REACT_APP_API + `/users/${userId}/`;

    var data = {
      stripe_paymentmethod_id: paymentMethodId,
      subscription_modifications: [
        {
          flat_stripe_price_id: currentFlatPriceId,
          metered_stripe_price_id: currentMeteredPriceId,
          action: 'update',
          new_flat_stripe_price_id: newFlatPriceId,
          new_metered_stripe_price_id: newMeteredPriceId,
        },
      ],
    };

    if (coupon !== '') {
      data['promo_code'] = coupon;
    }

    let options = {
      url: url,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIconfirmPayment = async (currentPlanID) => {
    let url = process.env.REACT_APP_API + '/users/confirm-payment/';

    let body = {
      current_plan_id: currentPlanID,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: body,
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIgetDomainsWhitelist = async (apiKey) => {
    let url =
      process.env.REACT_APP_API +
      '/credentials/domains-whitelist/?api_key=' +
      apiKey;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) {
          return error.response;
        }
      });
  };

  const APImanageBilling = async () => {
    let url = process.env.REACT_APP_API + '/users/stripe-portal/';

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options)
      .then((response) => {
        return response.data.url;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) {
          return error.response;
        }
      });
  };

  const APItriggerInvoicePayment = async () => {
    let url =
      process.env.REACT_APP_API + '/organization/invoices/trigger-payment/';

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) {
          return error.response;
        }
      });
  };

  const APIupdateDomainsWhitelist = async (apiKey, domains) => {
    let url =
      process.env.REACT_APP_API +
      '/credentials/domains-whitelist/?api_key=' +
      apiKey;

    let body = {
      domains: domains,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const processCSV = async (apiKey, apiPath, data) => {
    let url = process.env.REACT_APP_API_TOOLS + '/datavalidation/upload-csv/';

    let body = {
      user_id: getUserInformation().id,
      api_key: apiKey,
      slug_api_name: apiPath,
      organization_id: getUserInformation().organization_id,
      local_timestamp: `${getLocalDateAndTime()}`,
      data: data,
      total_row: data.length,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: body,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIRetrieveCheckoutSession = async (session_id) => {
    let url = process.env.REACT_APP_API + `/users/retrieve-checkout-session/`;

    var data = {
      session_id: session_id,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };
  const APIGetCheckoutSession = async (
    selectedPlanId,
    redirectUrl,
    isSuggestion = false,
    couponId = null,
    pricingCta = null,
  ) => {
    var data = {
      redirectUrl: redirectUrl || window.location.href,
      selectedPlanId: selectedPlanId,
      isSuggestion: isSuggestion,
      couponId: couponId,
    };
    let urlQuery = pricingCta ? `&pricing_cta=${pricingCta}` : '';
    let options = {
      url: process.env.REACT_APP_API + `/users/checkout-session/?${urlQuery}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APICancelPlan = async (productID, trackEventData) => {
    let url = process.env.REACT_APP_API + `/credentials/cancel-plan/`;
    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: {
        product_id: productID,
        properties: trackEventData,
      },
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APICouponRedeem = async (subscriptionId, trackEventData) => {
    let url = process.env.REACT_APP_API + `/credentials/sync-plan/`;
    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        stripe_subscription_id: subscriptionId,
        properties: trackEventData,
      },
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIGetChurnSolutionsHash = async (productId) => {
    const url = process.env.REACT_APP_API + '/churnsolution/get-hash/';
    let timestamp = new Date().getTime();
    let data = {
      timestamp: timestamp,
      product_id: productId,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIDowngradePlan = async (ProductID, NewPlanID) => {
    let url = process.env.REACT_APP_API + `/credentials/downgrade-plan/`;

    var data = {
      product_id: ProductID,
      new_plan_id: NewPlanID,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIRotateKey = async (apiKeyID) => {
    let url =
      process.env.REACT_APP_API + `/credentials/${apiKeyID}/rotate-key/`;

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIValidateEmail = async (uid, token) => {
    let url = process.env.REACT_APP_API + `/users/activate/${uid}/${token}/`;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIKeyChangeName = async (apiKeyID, name) => {
    let url =
      process.env.REACT_APP_API + `/credentials/${apiKeyID}/change-name/`;

    var data = {
      name,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIKeyCreateSlaveKey = async (apiKeyID, name) => {
    let url =
      process.env.REACT_APP_API + `/credentials/${apiKeyID}/create-slave-key/`;

    var data = {
      name,
    };

    let options = {
      url: url,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
      data: data,
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIKeyGetSlaveKeys = async (apiKeyID) => {
    let url =
      process.env.REACT_APP_API + `/credentials/${apiKeyID}/master-slave-keys/`;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIKeyDeleteSlaveKey = async (apiKeyID) => {
    let url =
      process.env.REACT_APP_API + `/credentials/${apiKeyID}/delete-slave-key/`;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      //withCredentials: 'true'
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIpushIdentifyEvent = async (mainInterest) => {
    let apiUrl = process.env.REACT_APP_API + '/users/push-identify-event/';
    try {
      const response = await axios.post(
        apiUrl,
        { main_interest: mainInterest },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(`Error making POST request to ${apiUrl}:`, error);
      return null;
    }
  };

  const APIgetFilesUploaded = async (apiPath, page = 1) => {
    const organizationId = getUserInformation().organization_id;
    const members = await APIgetOrganizationMembers(organizationId); // Call to APIgetOrganizationMembers with organization_id
    const memberIds = members.map((member) => member.id); // Extract only the IDs from the members
    let url =
      process.env.REACT_APP_API_TOOLS +
      `/datavalidation/${organizationId}/list-files/?page=${page}&slug_api_name=${apiPath}&member_ids=${memberIds.join(
        ',',
      )}`;
    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    return await axios(options).then((response) => {
      return response;
    });
  };

  const APIGetBannerPromoCode = async () => {
    let url = `${process.env.REACT_APP_API}/promotion-banners/`;

    let options = {
      url: url,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    return await axios(options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Error fetching banner promo code:', error);
        throw error;
      });
  };
  useEffect(() => {
    APIrefreshUserData();
  }, []);

  return (
    <APIContext.Provider
      value={{
        userInfo: userInfo,
        userApisData: userApisData,
        APIgetLocalProduct: APIgetLocalProduct,

        APIsignup: APIsignup,
        APIlogin: APIlogin,
        APIlogout: APIlogout,
        APIrefreshUserData: APIrefreshUserData,

        APIstartImpersonation: APIstartImpersonation,
        APIstopImpersonation: APIstopImpersonation,

        APIchangeEmail: APIchangeEmail,
        APIconfirmChangeEmail: APIconfirmChangeEmail,
        APIValidateEmail: APIValidateEmail,
        APIchangePassword: APIchangePassword,
        APIconfirmPasswordChange: APIconfirmPasswordChange,

        APIgetProducts: APIgetProducts,
        APIgetDomainsWhitelist: APIgetDomainsWhitelist,
        APIupdateDomainsWhitelist: APIupdateDomainsWhitelist,

        APIGetOrganization: APIGetOrganization,
        APIgetOrganizationMembers: APIgetOrganizationMembers,
        APIinviteOrganizationMember: APIinviteOrganizationMember,
        APIDeleteOrganizationMember: APIDeleteOrganizationMember,

        processCSV: processCSV,

        APIgetSelectedPlan: APIgetSelectedPlan,
        APIupdatePlan: APIupdatePlan,
        APIconfirmPayment: APIconfirmPayment,
        APImanageBilling: APImanageBilling,
        APIGetCheckoutSession: APIGetCheckoutSession,
        APIRetrieveCheckoutSession: APIRetrieveCheckoutSession,
        APICancelPlan: APICancelPlan,
        APIGetChurnSolutionsHash: APIGetChurnSolutionsHash,
        APICouponRedeem: APICouponRedeem,
        APIDowngradePlan: APIDowngradePlan,

        APItriggerInvoicePayment: APItriggerInvoicePayment,
        APIgetSuggestedPlan: APIgetSuggestedPlan,
        APIRotateKey: APIRotateKey,
        APIKeyChangeName: APIKeyChangeName,
        APIKeyCreateSlaveKey: APIKeyCreateSlaveKey,
        APIKeyGetSlaveKeys: APIKeyGetSlaveKeys,
        APIKeyDeleteSlaveKey: APIKeyDeleteSlaveKey,
        APIpushIdentifyEvent: APIpushIdentifyEvent,
        APISignupByGoogle: APISignupByGoogle,
        APIgetFilesUploaded: APIgetFilesUploaded,
        APIGetBannerPromoCode: APIGetBannerPromoCode,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};

export { APIContext as default, APIProvider };
