import React, { useState, useEffect, useContext, useRef } from 'react';
import { trackEvent } from '../Utils/SegmentAnalytics';
import APIContext from '../../contexts/APIContext';
import { useStripe } from '@stripe/react-stripe-js';
import { getNextMonthName, builtURLRedirectBanner } from './utils';
import SubscriptionPaymentModal from '../Modals/SubscriptionPaymentModal';

function Banner({ promoCode, currentComponent }) {
  const { APIGetCheckoutSession, APIrefreshUserData } = useContext(APIContext);
  const apiCallMade = useRef(false);
  const isMounted = useRef(true);

  const [productName, setProductName] = useState('');
  const [planId, setPlanId] = useState(null);
  const [nextMonth, setNextMonth] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [productId, setProductId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [bannerCouponId, setBannerCouponId] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(30);
  const stripe = useStripe();

  const isSuggestion = true;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const checkUserInfo = async () => {
      if (!apiCallMade.current) {
        const path = window.location.pathname;
        const isCorrectPath =
          (currentComponent === 'dashboard' && path.includes('/dashboard')) ||
          (currentComponent === 'api' && path.includes('/api/'));

        if (!isCorrectPath) return;

        try {
          apiCallMade.current = true;
          if (isMounted.current && promoCode) {
            setNextMonth(getNextMonthName());
            setRedirectUrl(builtURLRedirectBanner(promoCode));
            setProductId(promoCode.plan.api.id);
            setBannerCouponId(promoCode.promotion_code.stripe_id);
            setProductName(promoCode.plan.api.name);
            setPlanId(promoCode.plan.id);
            setDiscountPercentage(
              parseInt(promoCode.promotion_code.coupon.percentage_discount),
            );
          }
        } catch (error) {
          console.error('Error fetching promo code:', error);
        }
      }
    };

    checkUserInfo();
  }, [currentComponent]);

  const handleSubscribe = (productName, redirectUrl) => {
    trackEvent('Upgrade Banner - Offer clicked', {
      product_name: productName,
    });

    APIGetCheckoutSession(
      planId,
      redirectUrl,
      isSuggestion,
      bannerCouponId,
      'upgrade_banner_discount',
    )
      .then((response) => {
        return stripe.redirectToCheckout({
          sessionId: response.data['session_id'],
        });
      })
      .catch((error) => {
        trackEvent('Banner create session failed.', {
          error: error || 'Error when trying to create session',
        });
        setModalMessage(
          'An error occurred while creating the session. Please try again.',
        );
        setShowModal(true);
      });
  };

  return (
    <div>
      <div
        className="flex justify-between items-center rounded"
        style={{
          backgroundColor: '#0E3419',
          color: '#E0FBEF',
          padding: '20px',
        }}
      >
        <div className="px-5">
          <p className="text-lg font-semibold">
            Subscribe to the {productName} API for a special{' '}
            {discountPercentage}% discount
          </p>
          <p className="text-xs pt-2">Offer expires on {nextMonth} 1st.</p>
        </div>
        <button
          className="text-white font-semibold text-sm py-2 px-4 mr-4 rounded-md hover:bg-opacity-50"
          style={{ backgroundColor: '#20E492', color: '#1B2226' }}
          onClick={() => handleSubscribe(productName, redirectUrl)}
        >
          Subscribe today
        </button>
      </div>
      <SubscriptionPaymentModal
        isVisible={showModal}
        refreshApiData={() => APIrefreshUserData()}
        message={modalMessage}
        closeModal={() => {
          setShowModal(false);
        }}
        productId={productId}
        selectedPlan={planId}
      />
    </div>
  );
}

export default Banner;
